import { useState } from 'react';
import NextLink from 'next/link';
import { oneLine } from 'common-tags';

import { Icon, Link, Prose } from '../index';

import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeFaqsElementProps } from './types';

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};

/**
 * FaqsElement
 */
export const FaqsElement: FunctionComponent<TypeFaqsElementProps> = ({
  className = '',
  variant = 'primary',
  selected = false,
  title,
  children,
  defaultOpen = false,
  onClick,
  link,
}: TypeFaqsElementProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const realState = onClick ? selected : open;
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div
      className={oneLine`
      ${realState ? 'h-auto' : 'h-fit'}
      ${VARIANT_MAPS[variant]}
      ${className}
    `}
    >
      <div
        onClick={onClick ?? toggle}
        className={oneLine`
        text-heading--h5 flex w-full cursor-pointer flex-row items-center justify-between gap-4 border-b transition-all duration-200 hover:border-transparent hover:text-primary-600
        ${realState ? 'font-bold text-primary-600' : 'text-primary-400'}
      `}
      >
        <p>{title}</p>
        <Icon
          src={realState ? 'DropdowMinus' : 'DropdowPlus'}
          width={20}
          className="flex-shrink-0"
        />
      </div>

      <div
        className={oneLine`
        overflow-hidden transition-[max-height] duration-200
        ${realState ? 'mt-4 max-h-[2000px]' : 'max-h-0'}
      `}
      >
        {typeof children === 'string' ? (
          <Prose
            as="div"
            html={children}
            className={oneLine`
            font-thin transition-opacity duration-200
            ${realState ? 'opacity-100' : 'opacity-0'}
          `}
          />
        ) : (
          children
        )}
        {link?.src && (
          <NextLink href={link?.src} passHref prefetch={false}>
            <Link className="mt-4" arrow variant={link?.variant}>
              {link.label}
            </Link>
          </NextLink>
        )}
      </div>
    </div>
  );
};

FaqsElement.displayName = 'FaqsElement';
