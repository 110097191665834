import { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import NextLink from 'next/link';

import { Button, Caption, Container, FaqsElement, Link } from '@/atoms';
import { FaqsGroup } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeFaqsProps } from './types';

const PopUpBookingLavanda = dynamic(
  () => import('@/organisms/pop-up-booking-lavanda'),
  {
    ssr: false,
  }
);
/**
 * Faqs
 */
export const Faqs: FunctionComponent<TypeFaqsProps> = ({
  className = '',
  caption,
  title,
  link,
  imageDesk,
  faqsProps,
  columns,
  variant,
  cutLevel,
  cutLevelMobile,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  imageMobile,
  hascard,
  sidecard,
  faqsOpened,
  imageAlign = 'left',
  showCaption,
  ...config
}: TypeFaqsProps) => {
  const [formOpen, setFormOpen] = useState(false);

  const popUpToggle = () => {
    setFormOpen(!formOpen);
  };
  return (
    <>
      {sidecard && (
        <PopUpBookingLavanda
          apartmentsByBuilding={sidecard.accommodationSelect}
          buildings={sidecard.buildingSelect}
          show={formOpen}
          defaultApartment={sidecard.defaultApartment}
          defaultBuilding={sidecard.defaultBuilding}
          onClose={popUpToggle}
        />
      )}
      <Container
        cutLevel={cutLevel === null ? 50 : cutLevel}
        cutLevelMobile={cutLevelMobile === null ? 50 : cutLevel}
        className={`flex flex-col gap-20 md:flex-row md:gap-32 ${className}`}
        {...config}
      >
        <>
          {imageDesk && imageAlign === 'left' && (
            <div className="hidden min-w-fit md:block">
              <Image
                src={imageDesk}
                height={610}
                width={370}
                objectFit="cover"
                alt="Imagen de las faqs"
              />
            </div>
          )}
        </>

        <div className="flex w-full flex-col gap-7 md:gap-16">
          <div className="flex flex-col">
            {showCaption && (
              <Caption className="mb-4" variant={variant}>
                {caption}
              </Caption>
            )}

            <h3 className="text-heading text-heading-mobile--h3 text-primary-400 sm:text-heading--h3">
              {title}
            </h3>
          </div>

          <FaqsGroup
            defaultOpen={faqsOpened}
            variant={variant}
            columns={columns}
          >
            {faqsProps.map((child, index) => (
              <FaqsElement key={index} variant={variant} {...child}>
                {child.children}
              </FaqsElement>
            ))}
          </FaqsGroup>

          {link?.src && (
            <div className="flex justify-end">
              <NextLink href={link?.src} passHref prefetch={false}>
                <Link variant={variant} arrow={true}>
                  {link?.label}
                </Link>
              </NextLink>
            </div>
          )}
        </div>
        <>
          {imageDesk && imageAlign === 'right' && (
            <div className="hidden min-w-fit md:block">
              <Image
                src={imageDesk}
                height={610}
                width={370}
                objectFit="cover"
                alt="Imagen de las faqs"
              />
            </div>
          )}
        </>
        <>
          {hascard && sidecard && (
            <div className="h-full w-full overflow-hidden rounded-md md:max-w-[450px]">
              {sidecard.imagen && (
                <div className="relative h-52 md:h-96">
                  <Image
                    src={sidecard.imagen.sourceUrl}
                    layout="fill"
                    objectFit="cover"
                    alt="Imagen de las faqs"
                  />
                </div>
              )}

              <div className="flex flex-col gap-6 bg-surface-10 p-6 text-text-alternative md:py-9">
                {sidecard.caption && (
                  <Caption variant="alternative">{sidecard.caption}</Caption>
                )}

                {sidecard.title && (
                  <p className="text-heading text-heading--h3">
                    {sidecard.title}
                  </p>
                )}

                {sidecard.description && (
                  <p className="text-body text-body--primary">
                    {sidecard.description}
                  </p>
                )}

                <div className="flex w-full flex-row flex-wrap items-center gap-5">
                  {sidecard.button?.src && sidecard.button?.label && (
                    <NextLink
                      href={sidecard.button?.src}
                      passHref
                      prefetch={false}
                    >
                      <Button
                        variant={sidecard.button?.variant}
                        as="a"
                        size="small"
                      >
                        {sidecard.button?.label}
                      </Button>
                    </NextLink>
                  )}

                  {sidecard.link?.label && (
                    <Link
                      onClick={popUpToggle}
                      as="button"
                      variant={sidecard.link?.variant}
                      arrow
                    >
                      {sidecard.link?.label}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      </Container>
    </>
  );
};

Faqs.displayName = 'Faqs';
