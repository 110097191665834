import { useState } from 'react';

import { Grid } from '@/atoms';

import { toArray } from '@/utils/utils';

import type { FunctionComponent } from 'react';
import type { TypeFaqsGridProps } from './types';

/**
 * FaqsGrid
 */
export const FaqsGrid: FunctionComponent<TypeFaqsGridProps> = ({
  className = '',
  variant,
  children,
  columns,
  defaultOpen,
}: TypeFaqsGridProps) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const selectedIndexHandler = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <Grid xs={1} md={columns} lg={columns} className={`${className} !gap-5`}>
      {toArray(children).map((child, index) => (
        <Grid.Item key={index}>
          <child.type
            variant={variant}
            {...child.props}
            {...(defaultOpen
              ? { defaultOpen: true }
              : {
                  onClick: () => selectedIndexHandler(index),
                  selected: index === selectedIndex,
                })}
          />
        </Grid.Item>
      ))}
    </Grid>
  );
};

FaqsGrid.displayName = 'FaqsGrid';
